/**
* Splash screen
*/

const splashScreen = () => {
  const splash = document.querySelector<HTMLInputElement>(".js-splash");
  const lp = document.querySelector(".lp");

  if(!splash || !lp) return;
  const splashBg = splash.querySelector(".lp__splash-bg");
  const splashLogo = splash.querySelector(".lp__splash-logo");
  const header = document.querySelector(".lp-header");
  const fab = document.querySelector(".lp__fab");

  document.body.onload = () => {
    if(!splashBg || !splashLogo || !header || !fab) return;
    setTimeout(() => {
      splashLogo.classList.add('show');
      splashBg.classList.add('show');
    }, 1500);
    setTimeout(() => {
      splash.classList.add('hide');
      document.body.classList.remove("overflow");
    }, 3000);
    setTimeout(() => {
      header.classList.remove("splash");
    }, 3500);
    setTimeout(() => {
      lp.classList.remove('splash');
    }, 4000);
    setTimeout(() => {
      fab.classList.remove('splash');
    }, 4500);
  }

  
}

export default splashScreen
