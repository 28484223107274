/**
 * NAVIGATION
 */

const navigation = () => {
  const nav = document.querySelector(".lp-header__nav") as HTMLInputElement;
  const menuBtn = document.querySelector('.js-hamburger');
  const menu = document.querySelector('.js-header-menu') as HTMLInputElement;
  const menuLang = document.querySelector('.js-header-lang') as HTMLInputElement;

  // Nav toggle
  if(!nav || !menu || !menuBtn || !menuLang) return;
  menuBtn.addEventListener('click', () => {
    nav.classList.toggle('toggle');
    menu.classList.toggle('toggle');
    menuBtn.classList.toggle('toggle');
    menuLang.classList.toggle('toggle');

    let screenHeight = window.innerHeight;
    if(menu.classList.contains('toggle')) {
      menu.style.height = screenHeight + 'px';
    }
    else {
      menu.style.height = "0px";
    }

    document.documentElement.classList.toggle("overflow");
  });

  const menuWrap = menu.querySelector(".lp-header__nav-links");
  if(!menuWrap) return;
  const menuLinks = menuWrap.querySelectorAll(".lp-header__nav-item a");

  menuLinks.forEach(link => {
    link.addEventListener("click", () => {
      nav.classList.remove('toggle');
      menu.classList.remove('toggle');
      menuBtn.classList.remove('toggle');
      menuLang.classList.remove('toggle');
      document.documentElement.classList.remove("overflow");
    })
  })
}

export default navigation
