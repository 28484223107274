/**
* FAB toggle
*/

const fab = () => {
  const fab = document.querySelector(".js-fab");
  const fabTrigger = document.querySelector(".lp__about");

  if (!fab || !fabTrigger) return;
  window.addEventListener("scroll", () => {
    if(document.documentElement.scrollTop > 30) {
      fab.classList.add("toggle");
    }
    else {
      fab.classList.remove("toggle");
    }
  });
}

export default fab
