import facilitiesSlider from './components/facilitiesSlider'
import fab from './components/fab'
import headerScroll from './components/headerScroll'
import initAOS from './components/initAOS'
import mvSplide from './components/mvSplide'
import navigation from './components/navigation'
import splashScreen from './components/splashScreen'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    facilitiesSlider()
    splashScreen()
    navigation()
    headerScroll()
    mvSplide()
    fab()
    initAOS()
  },
  false
)
