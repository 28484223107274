/**
* Initialize AOS for animations
*/

const initAOS = () => {
  //@ts-ignore
  AOS.init({
    startEvent: 'DOMContentLoaded',
  });
}

export default initAOS
