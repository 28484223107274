/**
* Header scroll animation
*/

const headerScroll = () => {
  const header = document.querySelector(".lp-header");
  const mv = document.querySelector(".lp__mv");
  const hamburger = document.querySelector(".lp-header__hamburger");

  if(!header || !mv) return;
  const navLinks = header.querySelectorAll(".lp-header__nav-link");

  window.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 20) {
      header.classList.add("lp-header--scroll");
    }
    else {
      header.classList.remove("lp-header--scroll");
    }

    if(screen.width > 767) {
      let mvHeight = mv.scrollHeight - 190;
      if(document.documentElement.scrollTop > 20 && document.documentElement.scrollTop < mvHeight) {
        navLinks.forEach(navLink => {
          navLink.classList.add("scroll");
        })
      }
      else {
        navLinks.forEach(navLink => {
          navLink.classList.remove("scroll");
        })
      }
    }
    if (screen.width <= 1024 ) {
      if(!hamburger) return;
      let mvHeight = mv.scrollHeight;
      if(document.documentElement.scrollTop > 20 && document.documentElement.scrollTop < mvHeight) {
        hamburger.classList.add("scroll");
      }
      else {
        hamburger.classList.remove("scroll");
      }
    }
  })

  if (document.documentElement.scrollTop > 20) {
    header.classList.add("lp-header--scroll");
  }
  else {
    header.classList.remove("lp-header--scroll");
  }
}
  
  export default headerScroll
