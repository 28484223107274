declare var Splide: any

const facilitiesSlider = () => {
	const faci = document.querySelector(".js-facilities-splide");
	
	var faciSplide = new Splide( faci, {
		type: 'loop',
		pagination: false,
		arrows: false,
		focus: 'center',
		drag: 'free',
		interval: 2500,
		speed: 1000,
		autoplay: true,
	});

	faciSplide.mount();
}
  
export default facilitiesSlider
