/**
 * MV splide
 */

declare var Splide: any

const mvSplide = () => {
  const mv = document.querySelector(".lp__mv-splide");

  if (!mv) return;  
  var mvSplide = new Splide(mv, {
    type: 'fade',
    autoplay: true,
    interval: 3000,
    rewind: true,
    fixedWidth: '100vw',
    height: '100vh',
    pagination: false,
    arrows: false,
    drag: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    breakpoints: {
      767: {
        height: 580
      }
    }
  });

  setTimeout(() => {
    mvSplide.mount();
  }, 5500);
}
export default mvSplide
